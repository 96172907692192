import React, { useEffect, useState } from 'react';
import { SanityImageType } from '../../fragments';

import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, wrapSquareBracketedWithEmAndSpan } from '../../utils/utils';
import * as styles from './HeroBigVideo.module.scss';
import Image from './Image';
import LogosCarousel from './LogosCarousel';
import ModuleLayout from './ModuleLayout';
import Video from './Video';

export interface HeroBigVideoProps {
  title: string;
  trustedBrands: Array<{
    title: string;
    whiteLogo: SanityImageType;
    darkLogo: SanityImageType;
  }>;
  videoUrl: string;
  videoTitle?: string;
  videoSubtitle?: string;
  showClientBrands?: boolean;
}

const HeroBigVideo = ({
  title,
  videoSubtitle,
  videoTitle,
  videoUrl,
  showClientBrands,
  trustedBrands,
}: HeroBigVideoProps): React.ReactElement => {
  const [isTitleHighlighted, setIsTitleHighlighted] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsTitleHighlighted(true), 100);
  }, [isTitleHighlighted]);

  return (
    <ModuleLayout className={styles.container} contentClassName={styles.contentContainer}>
      <div className={styles.textContainer}>
        <h1 className={clsx(styles.title, isTitleHighlighted && styles.titleHighlighted)}>
          {wrapSquareBracketedWithEmAndSpan(title, replaceNewLinesWithBr)}
        </h1>
      </div>

      <div className={styles.videoContainer}>
        <Video
          url={videoUrl}
          className={styles.video}
          videoTitle={videoTitle}
          videoSubtitle={videoSubtitle}
          allowVideoIdFromQueryParams
        />
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.leftContainer}>
          {showClientBrands && (
            <div className={styles.brandsContainer}>
              <span className={styles.brandsContainerTitle}>
                Trusted By The World’s Most Loved Brands
              </span>
              <LogosCarousel
                logos={trustedBrands}
                className={styles.logoCarouselMobile}
                logoClassName={styles.logoContainer}
              />
              <div className={styles.logosContainer}>
                {trustedBrands.map((brand, i) => (
                  <div className={styles.logoContainer} key={i}>
                    <Image image={brand.darkLogo} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.whiteBackground}></div>
    </ModuleLayout>
  );
};

export default HeroBigVideo;
