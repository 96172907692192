import BlockContent from '@sanity/block-content-to-react';
import { easeOut, motion, useScroll, useTransform } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import serializers from '../../serializers';
import { RawPortableText } from '../../types';
import { useWindowDimensions } from '../../utils/hooks';
import { clsx, slugify } from '../../utils/utils';
import * as styles from './ModuleLayout.module.scss';

export interface ModuleLayoutProps {
  id?: string;
  title?: string;
  introText?: string;
  text?: RawPortableText;
  children?: React.ReactNode;
  withModuleDivider?: boolean;
  withItalicSubtitle?: boolean;
  className?: string;
  contentClassName?: string;
}

export function getModuleId(id?: string, title?: string) {
  return id || (title && slugify(title));
}

const ModuleLayout = ({
  id,
  title,
  introText,
  text,
  children,
  withModuleDivider,
  withItalicSubtitle,
  className,
  contentClassName,
}: ModuleLayoutProps): React.ReactElement => {
  const sectionRef = useRef<HTMLElement>(null);

  const [withEntryAnimations, setWithEntryAnimations] = useState(false);
  const { height: windowHeight } = useWindowDimensions();

  // Activate animations only on sections that would start
  // outside the screen if user was at the top of the page.
  useEffect(() => {
    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (sectionRect && windowHeight && sectionRect.top + window.scrollY > windowHeight) {
      setWithEntryAnimations(true);
    }
  }, []);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start 100%', 'start 30%'],
  });

  const opacityMV = useTransform(scrollYProgress, [0, 1], [0, 1], { ease: easeOut });
  const translateYMV = useTransform(scrollYProgress, [0, 1], ['20vh', '0vh'], { ease: easeOut });
  const titleDeviderWidthMV = useTransform(scrollYProgress, [0.3, 1], [0, 95], { ease: easeOut });

  return (
    <motion.section
      className={clsx(styles.moduleContainer, className)}
      id={getModuleId(id, title)}
      ref={sectionRef}
      style={
        withEntryAnimations
          ? {
              opacity: opacityMV,
              translateY: translateYMV,
            }
          : undefined
      }
    >
      <div className={clsx(styles.contentContainer, contentClassName)}>
        {title && (
          <>
            <h2 className={styles.title}>{title}</h2>
            <motion.div
              className={styles.titleDivider}
              style={withEntryAnimations ? { width: titleDeviderWidthMV } : undefined}
            ></motion.div>
          </>
        )}
        {introText && (
          <h3 className={clsx(styles.introText, withItalicSubtitle && styles.withItalicSubtitle)}>
            {introText}
          </h3>
        )}
        {text && (
          <BlockContent
            renderContainerOnSingleChild
            className={styles.text}
            blocks={text}
            serializers={serializers}
          />
        )}
        {children}
        {withModuleDivider && <div className={styles.divider}></div>}
      </div>
    </motion.section>
  );
};

export default ModuleLayout;
