import React from 'react';
import { FaTimes } from 'react-icons/fa';

import { clsx } from '../../utils/utils';
import * as styles from './Modal.module.scss';

interface ModalProps {
  children?: React.ReactNode;
  title?: string;
  introText?: string;
  className?: string;
  childrenClassName?: string;
  childrenWithNoVerticalPadding?: boolean;
  onClose: React.MouseEventHandler;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
}

const Modal = ({
  children,
  title,
  introText,
  childrenClassName,
  childrenWithNoVerticalPadding,
  className,
  onClose,
  onMouseDown,
  onMouseUp,
}: ModalProps): React.ReactElement => {
  return (
    <div
      className={clsx(
        className,
        styles.container,
        childrenWithNoVerticalPadding && styles.noVerticalPadding,
      )}
      onClick={e => e.stopPropagation()}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <div className={styles.closeContainer}>
        <FaTimes className={styles.icon} onClick={onClose} />
      </div>
      {title && (
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.titleDivider}></div>
        </div>
      )}
      {introText && <p className={styles.introText}>{introText}</p>}
      {children && <div className={clsx(childrenClassName, styles.children)}>{children}</div>}
    </div>
  );
};

export default Modal;
