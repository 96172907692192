import React from 'react';

import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../serializers';
import { RawPortableText } from '../../types';
import { clsx } from '../../utils/utils';
import ModuleLayout from '../ui/ModuleLayout';
import * as styles from './MethodSection.module.scss';

export interface MethodSectionProps {
  title: string;
  introText: string;
  textColumns: Array<{
    title: string;
    _rawText: RawPortableText;
  }>;
  equation: {
    strategyEquationTextIntro: string;
    strategyEquationText1: string;
    strategyEquationText2: string;
    strategyEquationText3: string;
  };
  className?: string;
}

const MethodSection = ({
  title,
  introText,
  textColumns,
  equation,
  className,
}: MethodSectionProps): React.ReactElement => {
  return (
    <ModuleLayout className={clsx(styles.container, className)} title={title} introText={introText}>
      <div className={styles.textColumnsContainer}>
        {textColumns.map((textColumn, i) => (
          <div className={styles.textColumnContainer} key={i}>
            <span className={clsx(styles.number)}>{i + 1}</span>
            <div className={styles.textContainer}>
              <h4 className={styles.textColumnTitle}>{textColumn.title}</h4>
              <BlockContent
                renderContainerOnSingleChild
                className={styles.textColumnText}
                blocks={textColumn._rawText}
                serializers={serializers}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.equationContainer}>
        <p className={styles.equationTextIntro}>{equation.strategyEquationTextIntro}</p>
        <div className={styles.equationWrapper}>
          <div className={styles.equationPart1}>
            <span>{equation.strategyEquationText1}</span>
            <span>+</span>
            <span>{equation.strategyEquationText2}</span>
          </div>
          <span className={styles.equationEquals}>=</span>
          <span className={styles.equationText3}>{equation.strategyEquationText3}</span>
        </div>
      </div>
    </ModuleLayout>
  );
};

export default MethodSection;
