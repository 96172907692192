// extracted by mini-css-extract-plugin
export var background = "CtaSection-module--background--92896";
export var button = "CtaSection-module--button--f27b1";
export var container = "CtaSection-module--container--881dd";
export var content = "CtaSection-module--content--960f6";
export var form = "CtaSection-module--form--154bf";
export var formContainer = "CtaSection-module--formContainer--67fbb";
export var text = "CtaSection-module--text--fba31";
export var textContainer = "CtaSection-module--textContainer--a2f29";
export var textStyleLargeTitle = "CtaSection-module--text-style-large-title--f2f17";
export var textStyleLargestTitle = "CtaSection-module--text-style-largest-title--9c7c1";
export var textStyleMediumTitle = "CtaSection-module--text-style-medium-title--a49a6";
export var textStyleSmallTitle = "CtaSection-module--text-style-small-title--5decd";
export var title = "CtaSection-module--title--3bdd0";
export var withForm = "CtaSection-module--withForm--c49ed";