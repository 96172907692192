import BlockContent from '@sanity/block-content-to-react';
import { useInView } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { CaseStudy, VideoAndQuote } from '../../fragments';
import serializers from '../../serializers';
import { RawPortableText } from '../../types';
import { getVideoTypeByUrl } from '../../utils/projectUtils';
import { getPortableTextAsString } from '../../utils/sanity';
import { clsx, truncateText, withDataLayer } from '../../utils/utils';
import Image from '../ui/Image';
import ModalOverlay from '../ui/ModalOverlay';
import ModuleLayout from '../ui/ModuleLayout';
import Video from '../ui/Video';
import * as styles from './CaseStudiesSection.module.scss';

export interface CaseStudiesSectionProps {
  caseStudies: Array<CaseStudy>;
}

const CaseStudiesSection = ({ caseStudies }: CaseStudiesSectionProps): React.ReactElement => {
  const [modalOpenIndex, setModalOpenIndex] = useState<number | null>(null);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  function renderInfo(title: string, info: string) {
    return (
      <div className={styles.info}>
        <p className={styles.infoTitle}>{title} </p>
        <span className={styles.infoText}>{info}</span>
      </div>
    );
  }

  function renderResultNumber(title: string, text: RawPortableText, i: number) {
    return (
      <div className={clsx(styles.resultContainer)} key={i}>
        <span className={styles.resultTitle}> {title}</span>
        <BlockContent
          renderContainerOnSingleChild
          blocks={text}
          serializers={serializers}
          className={styles.resultText}
        />
      </div>
    );
  }

  function renderTextBlock(title: string, text: RawPortableText, video?: VideoAndQuote) {
    const videoType = video?.url && getVideoTypeByUrl(video.url);
    const isAVerticalVideo =
      video?.url &&
      !!(
        video.isAVerticalVideo ||
        video.url.match(/\/shorts\//) ||
        videoType === 'tiktok' ||
        videoType === 'instagram'
      );

    return (
      <div className={styles.textBlockContainer}>
        <span className={styles.textBlockTitle}>{title}</span>
        <div className={styles.textBlockTitleDivider}></div>
        <BlockContent
          className={styles.textBlockText}
          blocks={text}
          renderContainerOnSingleChild
          serializers={serializers}
        />
        {video?.url && (
          <div className={clsx(styles.videoContainer, isAVerticalVideo && styles.verticalVideo)}>
            <div className={clsx(styles.videoWrapper)}>
              <div className={styles.videoBackground}></div>
              <Video
                url={video.url}
                className={clsx(styles.video, videoType === 'youtube' && styles.youtubeVideo)}
                fitContent
                isAVerticalVideo={video.isAVerticalVideo}
              />
            </div>
            {(video.quoteTitle || video.quote) && (
              <div className={styles.quoteContainer}>
                <div className={styles.quoteWrapper}>
                  {video.quoteTitle && (
                    <span className={styles.quoteTitle}>— {video.quoteTitle}</span>
                  )}
                  {video.quote && <blockquote className={styles.quote}>"{video.quote}"</blockquote>}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <ModuleLayout className={styles.container} title={'Case Studies'}>
      <div className={styles.caseStudiesContainer} ref={ref}>
        {caseStudies.map((caseStudy, i) => (
          <div
            className={styles.caseStudyContainer}
            key={i}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(11, 33, 56, 0) 28.65%, #0B2138 85.42%), url(${caseStudy.image.asset.url}?auto=format&q=75&h=1260)`,
              transform: isInView ? 'none' : `translateX(100vw)`,
              opacity: isInView ? 1 : 0,
              transition: `transform 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) calc(${
                i * 0.2
              }s), opacity 0.6s cubic-bezier(0.17, 0.55, 0.55, 1) calc(${
                i * 0.2
              }s), background-size 0.3s ease`,
            }}
            onClick={() => {
              setModalOpenIndex(i);
              withDataLayer(dataLayer => {
                dataLayer.push({ event: 'case-study-open', caseStudyTitle: caseStudy.title });
              });
            }}
          >
            <div className={styles.caseStudySpan}>
              <div className={styles.line}></div>
              <span className={styles.caseStudySpan}> Case study</span>
            </div>
            <h3 className={styles.name}>{caseStudy.title}</h3>
            <div className={styles.nameDivider}></div>
            <p className={styles.results}>
              {truncateText(getPortableTextAsString(caseStudy._rawDescription), 4 * 45)}
            </p>
            <button className={styles.button}>
              View case <FaArrowRight className={styles.arrow} />
            </button>
            <ModalOverlay
              onClose={() => setModalOpenIndex(null)}
              open={i === modalOpenIndex}
              modalContainerClassName={styles.modal}
              childrenWithNoVerticalPadding
            >
              <div className={styles.modalContentContainer}>
                <div className={styles.imageAndInfoContainer}>
                  <div className={styles.imageContainer}>
                    <Image image={caseStudy.image} />
                  </div>
                  <div className={styles.infoContentContainer}>
                    <div className={styles.caseStudyBasicInfo}>
                      <span className={styles.modalTitle}>{caseStudy.title}</span>
                      <BlockContent
                        className={styles.modalText}
                        blocks={caseStudy._rawDescription}
                        renderContainerOnSingleChild
                        serializers={serializers}
                      />
                    </div>
                    {(caseStudy.goal ||
                      caseStudy.industry ||
                      caseStudy.companySize ||
                      caseStudy.location) && (
                      <div className={styles.infoWrapper}>
                        {caseStudy.goal && renderInfo('Goal', caseStudy.goal)}
                        {caseStudy.industry && renderInfo('Industry', caseStudy.industry)}
                        {caseStudy.companySize && renderInfo('Company Size', caseStudy.companySize)}
                        {caseStudy.location && renderInfo('Location', caseStudy.location)}
                      </div>
                    )}
                    {caseStudy.resultsNumbers && caseStudy.resultsNumbers.length > 0 && (
                      <div className={styles.resultsItemsContainer}>
                        {caseStudy.resultsNumbers.map((item, i) =>
                          renderResultNumber(item.title, item._rawText, i),
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {caseStudy._rawIntroduction &&
                  renderTextBlock(
                    'Introduction',
                    caseStudy._rawIntroduction,
                    caseStudy.introductionVideo,
                  )}

                {caseStudy._rawBackground &&
                  renderTextBlock(
                    'Background',
                    caseStudy._rawBackground,
                    caseStudy.backgroundVideo,
                  )}

                {caseStudy._rawChallenge &&
                  renderTextBlock('Challenge', caseStudy._rawChallenge, caseStudy.challengeVideo)}

                {caseStudy._rawSolution &&
                  renderTextBlock('Solutions', caseStudy._rawSolution, caseStudy.solutionVideo)}
              </div>
              <div className={styles.resultsBlockContainer}>
                <span className={styles.textBlockTitle}>The Results</span>
                <div className={styles.textBlockTitleDivider}></div>

                <div
                  className={clsx(
                    styles.resultsTextBlock,
                    caseStudy.resultsNumbers &&
                      caseStudy.resultsNumbers.length > 0 &&
                      styles.largerMarginTop,
                  )}
                >
                  {caseStudy.resultsNumbers && caseStudy.resultsNumbers.length > 0 && (
                    <div className={styles.resultsItemsContainer}>
                      {caseStudy.resultsNumbers.map((item, i) =>
                        renderResultNumber(item.title, item._rawText, i),
                      )}
                    </div>
                  )}
                  <BlockContent
                    className={styles.resultsText}
                    blocks={caseStudy._rawResults}
                    renderContainerOnSingleChild
                    serializers={serializers}
                  />
                </div>
                {caseStudy.beforeImage && caseStudy.afterImage && (
                  <div className={styles.beforeAndAfterContainer}>
                    <div className={styles.beforeContainer}>
                      <span className={styles.tableTitle}>Before</span>
                      <div className={styles.tableImageContainer}>
                        <Image image={caseStudy.beforeImage} />
                      </div>
                    </div>
                    <div className={styles.afterContainer}>
                      <span className={styles.tableTitle}>After</span>
                      <div className={styles.tableImageContainer}>
                        <Image image={caseStudy.afterImage} />
                      </div>
                    </div>
                  </div>
                )}
                {renderTextBlock('Summary', caseStudy._rawSummaryText)}
              </div>
            </ModalOverlay>
          </div>
        ))}
      </div>
    </ModuleLayout>
  );
};

export default CaseStudiesSection;
