import React from 'react';

import BlockContent from '@sanity/block-content-to-react';
import { SanityImageType } from '../../fragments';
import serializers from '../../serializers';
import { RawPortableText } from '../../types';
import { clsx } from '../../utils/utils';
import Image from '../ui/Image';
import ModuleLayout from '../ui/ModuleLayout';
import * as styles from './NoteSection.module.scss';

export interface NoteSectionProps {
  title: string;
  subtitle?: string;
  rows: Array<{
    image?: SanityImageType;
    title?: string;
    _rawText: RawPortableText;
  }>;
  className?: string;
}

const NoteSection = ({
  title,
  subtitle,
  rows,
  className,
}: NoteSectionProps): React.ReactElement => {
  return (
    <ModuleLayout
      className={clsx(styles.container, className)}
      title={title}
      introText={subtitle}
      withItalicSubtitle
    >
      <div className={styles.rowsContainer}>
        {rows.map((row, i) => (
          <div className={clsx(styles.rowContainer, row.title && styles.narrowColumn)} key={i}>
            <div className={clsx(styles.leftColumnContainer)}>
              {row.image && (
                <div className={styles.imageContainer}>
                  <Image image={row.image} className={styles.image} />
                </div>
              )}
              {row.title && <h2 className={styles.rowTitle}>{row.title}</h2>}
            </div>
            <div className={clsx(styles.textContainer, styles.column)}>
              <BlockContent
                renderContainerOnSingleChild
                className={styles.rowText}
                blocks={row._rawText}
                serializers={serializers}
              />
            </div>
          </div>
        ))}
      </div>
    </ModuleLayout>
  );
};

export default NoteSection;
