import React from 'react';

import { SanityImageType } from '../../fragments';
import { clsx } from '../../utils/utils';
import Image from './Image';
import * as styles from './LogosCarousel.module.scss';

const ELEMENT_ANIMATION_DURATION = 1.5;

export type LogosCarouselProps = {
  logos: Array<{
    title: string;
    whiteLogo: SanityImageType;
    darkLogo: SanityImageType;
  }>;
  logoClassName?: string;
  className?: string;
};

const LogosCarousel = ({
  logos,
  logoClassName,
  className,
}: LogosCarouselProps): React.ReactElement => {
  function renderElements() {
    return logos.map((logo, i) => (
      <div key={i} className={logoClassName}>
        <Image key={i} image={logo.darkLogo} />
      </div>
    ));
  }
  return (
    <div className={clsx(styles.elementsWrapper, className)}>
      <div
        className={styles.elementsContainer}
        style={{
          animationDuration: `${ELEMENT_ANIMATION_DURATION * (logos.length + 1)}s`,
        }}
      >
        {renderElements()}
        <div className={styles.elementsContainerCopy}>{renderElements()}</div>
      </div>
    </div>
  );
};

export default LogosCarousel;
