import React from 'react';

import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../serializers';
import { RawPortableText } from '../../types';
import { clsx } from '../../utils/utils';
import ModuleLayout from '../ui/ModuleLayout';
import * as styles from './JourneySection.module.scss';

export interface JourneySectionProps {
  title: string;
  textBlocks: Array<{
    title: string;
    _rawText: RawPortableText;
  }>;
  className?: string;
}

const JourneySection = ({
  title,
  textBlocks,
  className,
}: JourneySectionProps): React.ReactElement => {
  return (
    <ModuleLayout className={clsx(styles.container, className)} title={title}>
      <div className={styles.textBlocksContainer}>
        {textBlocks.map((textBlock, i) => (
          <div className={styles.textBlockContainer} key={i}>
            <div className={styles.orderContainer}>
              <span className={styles.orderTitle}>{i + 1}</span>
            </div>
            <div className={styles.textContainer}>
              <h4 className={styles.textBlockTitle}>{textBlock.title}</h4>
              <BlockContent
                renderContainerOnSingleChild
                className={styles.textBlockText}
                blocks={textBlock._rawText}
                serializers={serializers}
              />
            </div>
          </div>
        ))}
      </div>
    </ModuleLayout>
  );
};

export default JourneySection;
