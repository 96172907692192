// extracted by mini-css-extract-plugin
export var bottomContainer = "HeroBigVideo-module--bottomContainer--7879e";
export var brandsContainer = "HeroBigVideo-module--brandsContainer--767d4";
export var brandsContainerTitle = "HeroBigVideo-module--brandsContainerTitle--5808f";
export var container = "HeroBigVideo-module--container--23f78";
export var contentContainer = "HeroBigVideo-module--contentContainer--e78b6";
export var leftContainer = "HeroBigVideo-module--leftContainer--5805f";
export var logoCarouselMobile = "HeroBigVideo-module--logoCarouselMobile--8da48";
export var logoContainer = "HeroBigVideo-module--logoContainer--1af49";
export var logosContainer = "HeroBigVideo-module--logosContainer--fa9dd";
export var textContainer = "HeroBigVideo-module--textContainer--9482f";
export var textStyleLargeTitle = "HeroBigVideo-module--text-style-large-title--ffe24";
export var textStyleLargestTitle = "HeroBigVideo-module--text-style-largest-title--3ebf8";
export var textStyleMediumTitle = "HeroBigVideo-module--text-style-medium-title--c0668";
export var textStyleSmallTitle = "HeroBigVideo-module--text-style-small-title--dd8ca";
export var title = "HeroBigVideo-module--title--e86b6";
export var titleHighlighted = "HeroBigVideo-module--titleHighlighted--c6fa2";
export var video = "HeroBigVideo-module--video--9301f";
export var videoContainer = "HeroBigVideo-module--videoContainer--783cd";
export var whiteBackground = "HeroBigVideo-module--whiteBackground--32873";