// extracted by mini-css-extract-plugin
export var children = "Modal-module--children--e6fe7";
export var closeContainer = "Modal-module--closeContainer--78955";
export var container = "Modal-module--container--cd0bb";
export var icon = "Modal-module--icon--9ef77";
export var introText = "Modal-module--introText--386fa";
export var noVerticalPadding = "Modal-module--noVerticalPadding--50a8a";
export var textStyleLargeTitle = "Modal-module--text-style-large-title--511f1";
export var textStyleLargestTitle = "Modal-module--text-style-largest-title--1eabb";
export var textStyleMediumTitle = "Modal-module--text-style-medium-title--cd1df";
export var textStyleSmallTitle = "Modal-module--text-style-small-title--86359";
export var title = "Modal-module--title--e928f";
export var titleDivider = "Modal-module--titleDivider--37a19";