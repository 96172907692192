// extracted by mini-css-extract-plugin
export var afterContainer = "CaseStudiesSection-module--afterContainer--e86bd";
export var afterResultsText = "CaseStudiesSection-module--afterResultsText--0206a";
export var arrow = "CaseStudiesSection-module--arrow--2a274";
export var beforeAndAfterContainer = "CaseStudiesSection-module--beforeAndAfterContainer--38487";
export var beforeContainer = "CaseStudiesSection-module--beforeContainer--a897e";
export var button = "CaseStudiesSection-module--button--e8106";
export var byTheNumbersContainer = "CaseStudiesSection-module--byTheNumbersContainer--4a76a";
export var byTheNumbersText = "CaseStudiesSection-module--byTheNumbersText--1d091";
export var byTheNumbersTitle = "CaseStudiesSection-module--byTheNumbersTitle--aa5eb";
export var caseStudiesContainer = "CaseStudiesSection-module--caseStudiesContainer--d4c74";
export var caseStudyBasicInfo = "CaseStudiesSection-module--caseStudyBasicInfo--37ddb";
export var caseStudyContainer = "CaseStudiesSection-module--caseStudyContainer--fd6a7";
export var caseStudySpan = "CaseStudiesSection-module--caseStudySpan--27492";
export var container = "CaseStudiesSection-module--container--ce2cd";
export var imageAndInfoContainer = "CaseStudiesSection-module--imageAndInfoContainer--0ce2f";
export var imageContainer = "CaseStudiesSection-module--imageContainer--25eb5";
export var info = "CaseStudiesSection-module--info--1cbc9";
export var infoContentContainer = "CaseStudiesSection-module--infoContentContainer--12808";
export var infoText = "CaseStudiesSection-module--infoText--b3118";
export var infoTitle = "CaseStudiesSection-module--infoTitle--c6c4c";
export var infoWrapper = "CaseStudiesSection-module--infoWrapper--c9c4b";
export var largerMarginTop = "CaseStudiesSection-module--largerMarginTop--bc1dc";
export var line = "CaseStudiesSection-module--line--28452";
export var modalContentContainer = "CaseStudiesSection-module--modalContentContainer--7ad5a";
export var modalText = "CaseStudiesSection-module--modalText--6454a";
export var modalTitle = "CaseStudiesSection-module--modalTitle--3659f";
export var name = "CaseStudiesSection-module--name--88edf";
export var nameDivider = "CaseStudiesSection-module--nameDivider--3c972";
export var quote = "CaseStudiesSection-module--quote--4e03a";
export var quoteContainer = "CaseStudiesSection-module--quoteContainer--b122e";
export var quoteTitle = "CaseStudiesSection-module--quoteTitle--b37e4";
export var quoteWrapper = "CaseStudiesSection-module--quoteWrapper--ad267";
export var resultContainer = "CaseStudiesSection-module--resultContainer--37363";
export var resultText = "CaseStudiesSection-module--resultText--89fc0";
export var resultTitle = "CaseStudiesSection-module--resultTitle--14949";
export var results = "CaseStudiesSection-module--results--81318";
export var resultsBlockContainer = "CaseStudiesSection-module--resultsBlockContainer--65022";
export var resultsItemsContainer = "CaseStudiesSection-module--resultsItemsContainer--8405c";
export var resultsText = "CaseStudiesSection-module--resultsText--7618b";
export var resultsTextBlock = "CaseStudiesSection-module--resultsTextBlock--0b3c6";
export var tableImageContainer = "CaseStudiesSection-module--tableImageContainer--7d966";
export var tableTitle = "CaseStudiesSection-module--tableTitle--5487b";
export var textBlockContainer = "CaseStudiesSection-module--textBlockContainer--55558";
export var textBlockText = "CaseStudiesSection-module--textBlockText--f4065";
export var textBlockTitle = "CaseStudiesSection-module--textBlockTitle--7937c";
export var textBlockTitleDivider = "CaseStudiesSection-module--textBlockTitleDivider--6dd9c";
export var textStyleLargeTitle = "CaseStudiesSection-module--text-style-large-title--790c2";
export var textStyleLargestTitle = "CaseStudiesSection-module--text-style-largest-title--d0d26";
export var textStyleMediumTitle = "CaseStudiesSection-module--text-style-medium-title--e62d3";
export var textStyleSmallTitle = "CaseStudiesSection-module--text-style-small-title--813d2";
export var verticalVideo = "CaseStudiesSection-module--verticalVideo--06393";
export var video = "CaseStudiesSection-module--video--c0619";
export var videoBackground = "CaseStudiesSection-module--videoBackground--e0773";
export var videoContainer = "CaseStudiesSection-module--videoContainer--6008f";
export var videoWrapper = "CaseStudiesSection-module--videoWrapper--d13eb";