// extracted by mini-css-extract-plugin
export var column = "NoteSection-module--column--d4a09";
export var container = "NoteSection-module--container--9ada0";
export var imageContainer = "NoteSection-module--imageContainer--59cc0";
export var leftColumnContainer = "NoteSection-module--leftColumnContainer--413ee";
export var narrowColumn = "NoteSection-module--narrowColumn--add6f";
export var rowContainer = "NoteSection-module--rowContainer--24b59";
export var rowText = "NoteSection-module--rowText--dbed1";
export var rowTitle = "NoteSection-module--rowTitle--a1943";
export var rowsContainer = "NoteSection-module--rowsContainer--4e51e";
export var textStyleLargeTitle = "NoteSection-module--text-style-large-title--71476";
export var textStyleLargestTitle = "NoteSection-module--text-style-largest-title--16690";
export var textStyleMediumTitle = "NoteSection-module--text-style-medium-title--0ef55";
export var textStyleSmallTitle = "NoteSection-module--text-style-small-title--7d066";