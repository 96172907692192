// extracted by mini-css-extract-plugin
export var authorContainer = "TestimonialCarousel-module--authorContainer--5992a";
export var authorInfo = "TestimonialCarousel-module--authorInfo--aa11f";
export var authorName = "TestimonialCarousel-module--authorName--68aa5";
export var authorPosition = "TestimonialCarousel-module--authorPosition--fd5c7";
export var button = "TestimonialCarousel-module--button--7a51e";
export var buttonGroup = "TestimonialCarousel-module--buttonGroup--cf746";
export var buttonIcon = "TestimonialCarousel-module--buttonIcon--bb7ec";
export var card = "TestimonialCarousel-module--card--55fed";
export var carousel = "TestimonialCarousel-module--carousel--f75f0";
export var desktop = "TestimonialCarousel-module--desktop--4564f";
export var divider = "TestimonialCarousel-module--divider--dce24";
export var imageContainer = "TestimonialCarousel-module--imageContainer--067a8";
export var mobile = "TestimonialCarousel-module--mobile--4ff55";
export var noArrows = "TestimonialCarousel-module--noArrows--8b7cd";
export var text = "TestimonialCarousel-module--text--a7517";
export var textStyleLargeTitle = "TestimonialCarousel-module--text-style-large-title--a8d59";
export var textStyleLargestTitle = "TestimonialCarousel-module--text-style-largest-title--f5d8b";
export var textStyleMediumTitle = "TestimonialCarousel-module--text-style-medium-title--d3a53";
export var textStyleSmallTitle = "TestimonialCarousel-module--text-style-small-title--a8add";
export var visuallyHidden = "TestimonialCarousel-module--visuallyHidden--3dd96";