// extracted by mini-css-extract-plugin
export var contentContainer = "ModuleLayout-module--contentContainer--a7903";
export var divider = "ModuleLayout-module--divider--95b5b";
export var introText = "ModuleLayout-module--introText--86a99";
export var moduleContainer = "ModuleLayout-module--moduleContainer--89bc7";
export var text = "ModuleLayout-module--text--658c0";
export var textStyleLargeTitle = "ModuleLayout-module--text-style-large-title--86aeb";
export var textStyleLargestTitle = "ModuleLayout-module--text-style-largest-title--f1544";
export var textStyleMediumTitle = "ModuleLayout-module--text-style-medium-title--83b94";
export var textStyleSmallTitle = "ModuleLayout-module--text-style-small-title--64b10";
export var title = "ModuleLayout-module--title--51762";
export var titleDivider = "ModuleLayout-module--titleDivider--55e12";
export var withItalicSubtitle = "ModuleLayout-module--withItalicSubtitle--be5ae";