import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { SanityImageType } from '../../fragments';
import ModuleLayout from '../ui/ModuleLayout';
import TestimonialCarousel from '../ui/TestimonialCarousel';
import * as styles from './TestimonialsSection.module.scss';

interface Testimonial {
  quote: string;
  author: string;
  position: string;
  image: SanityImageType;
}

export type TestimonialsSectionProps = {
  className?: string;
} & (
  | {
      testimonialsToUse?: 'fromHookPointWebsite';
      testimonials?: never;
      referencesToUse: 'all' | 'chooseManually';
      testimonialReferences: Array<Testimonial>;
    }
  | {
      testimonialsToUse: 'writeManually';
      testimonials: Array<Testimonial>;
      referencesToUse?: never;
      testimonialReferences?: never;
    }
);

interface QueryData {
  allSanityTestimonial: {
    nodes: Array<Testimonial>;
  };
}

function TestimonialsSection(props: TestimonialsSectionProps): React.ReactElement {
  const data = useStaticQuery<QueryData>(graphql`
    {
      allSanityTestimonial: allSanityHpWebsiteTestimonial(sort: { fields: orderRank }) {
        nodes {
          quote
          author
          position
          image {
            ...SanityImage
          }
        }
      }
    }
  `);

  const { testimonialsToUse, referencesToUse, testimonialReferences, testimonials, className } =
    props;

  return (
    <ModuleLayout className={className} contentClassName={styles.contentContainer}>
      <TestimonialCarousel
        items={
          (testimonialsToUse === 'fromHookPointWebsite' &&
            referencesToUse === 'chooseManually' &&
            testimonialReferences) ||
          (testimonialsToUse === 'fromHookPointWebsite' &&
            referencesToUse === 'all' &&
            data.allSanityTestimonial.nodes) ||
          (testimonialsToUse === 'writeManually' && testimonials) ||
          data.allSanityTestimonial.nodes
        }
      />
    </ModuleLayout>
  );
}

export default TestimonialsSection;
