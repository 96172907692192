// extracted by mini-css-extract-plugin
export var equationContainer = "MethodSection-module--equationContainer--fd583";
export var equationEquals = "MethodSection-module--equationEquals--9743c";
export var equationPart1 = "MethodSection-module--equationPart1--5badd";
export var equationText3 = "MethodSection-module--equationText3--021b5";
export var equationTextIntro = "MethodSection-module--equationTextIntro--c86e2";
export var equationWrapper = "MethodSection-module--equationWrapper--7375f";
export var number = "MethodSection-module--number--0db0b";
export var textColumnContainer = "MethodSection-module--textColumnContainer--4872a";
export var textColumnText = "MethodSection-module--textColumnText--45dc8";
export var textColumnTitle = "MethodSection-module--textColumnTitle--51b6a";
export var textColumnsContainer = "MethodSection-module--textColumnsContainer--0992b";
export var textStyleLargeTitle = "MethodSection-module--text-style-large-title--a64b3";
export var textStyleLargestTitle = "MethodSection-module--text-style-largest-title--23068";
export var textStyleMediumTitle = "MethodSection-module--text-style-medium-title--f8de6";
export var textStyleSmallTitle = "MethodSection-module--text-style-small-title--547f3";