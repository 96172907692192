import React from 'react';

import BlockContent from '@sanity/block-content-to-react';
import serializers from '../../serializers';
import { RawPortableText } from '../../types';
import { clsx } from '../../utils/utils';
import ModuleLayout from '../ui/ModuleLayout';
import * as styles from './WhyUsSection.module.scss';

export interface WhyUsSectionProps {
  title: string;
  textRows: Array<{
    title: string;
    _rawText: RawPortableText;
  }>;
  className?: string;
}

const WhyUsSection = ({ title, textRows, className }: WhyUsSectionProps): React.ReactElement => {
  return (
    <ModuleLayout className={clsx(styles.container, className)} title={title}>
      <div className={styles.textRowsContainer}>
        {textRows.map((textRow, i) => (
          <div className={styles.textRow} key={i}>
            <h3 className={styles.rowTitle}>{textRow.title}</h3>
            <BlockContent
              renderContainerOnSingleChild
              className={styles.text}
              blocks={textRow._rawText}
              serializers={serializers}
            />
          </div>
        ))}
      </div>
    </ModuleLayout>
  );
};

export default WhyUsSection;
